import classNames from 'classnames';

import { TEMPO } from 'context/BrandContext';
import useBrand from 'hooks/useBrand';
import bannerTacos from 'images/banner-tacos.png';
import TempoRestartBannerCreamyTomato from 'images/messageCenter/creamy-tomato-pasta.png';
import style from 'styles/components/messageCenter/PauseMessage';

const STYLE_SUCCESS_TYPE = 'success';

const PauseMessage = ({ actionFunction, actionText, heading, isSubmitting, subheading }) => {
  const isTempo = useBrand(TEMPO);

  return (
    <>
      <div
        className={classNames(style.success, {
          [style['success--tempo']]: isTempo,
        })}
      >
        <div
          className={classNames(
            'flex',
            'justify-between',
            'bp2:pl-4',
            'bp2:pr-24',
            'bp2:p-4',
            'bp2:py-0',
            style.banner
          )}
        >
          <div className={classNames('flex', style.textAndImage)}>
            <img
              className={classNames(style.tacoImage, isTempo ? 'ml-6 mr-12' : 'ml-10', 'max-h-24')}
              src={isTempo ? TempoRestartBannerCreamyTomato : bannerTacos}
              alt={isTempo ? 'Tempo Creamy Tomato Penne' : 'Banner Tacos'}
            />

            <div className={classNames(style.textContainer)}>
              <p className={classNames(style.heading, 'h4 mb-2 font-bold')}>{heading}</p>
              <p className={classNames(style.description, 'text-left font-normal')}>{subheading}</p>
            </div>
          </div>
          <div className="flex">
            <button
              onClick={() => actionFunction()}
              className={classNames(style.buttonReactivate, 'btn w-full bp2:min-w-48', {
                btnTempoYellow: isTempo,
                'btnPrimary btnOutlined': !isTempo,
              })}
              disabled={isSubmitting}
            >
              {actionText}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

PauseMessage.propTypes = {
  actionFunction: PropTypes.func.isRequired,
  actionText: PropTypes.string,
  subheading: PropTypes.string,
  heading: PropTypes.string.isRequired,
  messageStyle: PropTypes.oneOf([STYLE_SUCCESS_TYPE]),
  user: PropTypes.shape({
    userLoading: PropTypes.bool,
    paused: PropTypes.bool,
    uuid: PropTypes.string,
  }).isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

export default PauseMessage;
