import cn from 'classnames';
import FocusTrap from 'focus-trap-react';

import CheckCircle from 'components/icons/accent/CheckCircle';
import CloseX from 'components/icons/navigation/CloseX';

export const COLOR_SCHEME_GREEN = 'green';
export const COLOR_SCHEME_GRAY = 'gray';
export const COLOR_SCHEME_RED = 'red';
export const COLOR_SCHEME_WHITE = 'white';
export const COLOR_SCHEME_TEMPO = 'tempo';
export const COLOR_SCHEME_CREAM = 'cream';
export const COLOR_SCHEME_TEMPO_BLUE = 'tempoBlue';

const Modal = ({
  bodyPadding = '',
  bodyStyle = '',
  colorScheme = COLOR_SCHEME_GREEN,
  containerStyle = '',
  contentId = '',
  dismissable = true,
  iconComponent: Icon = CheckCircle,
  onDeactivate = null,
  onDismiss = () => {},
  showIcon = true,
  subtitle = '',
  subtitleStyle = '',
  title = '',
  titleStyle = '',
  topBarStyle = '',
  xStyle = '',
  children,
  contentClassname,
  transitionRef,
}) => (
  <FocusTrap
    focusTrapOptions={{
      onActivate: () => document.body.classList.add('overflow-hidden'),
      onDeactivate: () => {
        document.body.classList.remove('overflow-hidden');
        onDeactivate ? onDeactivate() : onDismiss();
      },
    }}
  >
    <div className="react-modal" ref={transitionRef}>
      <div
        className={cn(
          containerStyle,
          'fixed',
          'directional-center',
          'flex',
          'itemsCenter',
          'justify-center',
          'p-4',
          'w-screen',
          'min-w-300',
          'min-h-300',
          'max-h-screen',
          'z-modal',
          'max-w-550',
          'overflow-y-auto'
        )}
      >
        <div
          className={cn(
            'max-h-85vh w-full overflow-auto rounded bg-white-100 shadow-xl',
            contentClassname
          )}
          id={contentId}
        >
          <div
            className={cn(
              topBarStyle,
              'sticky',
              'top-0',
              titleStyle ? '' : 'flex',
              'justify-between',
              'items-center',
              'rounded-t',
              'p-5',
              'bp1:py-6',
              'bp1:px-8',
              {
                'bg-green-100 text-white-100': colorScheme === COLOR_SCHEME_GREEN,
                'bg-red-100 text-white-100': colorScheme === COLOR_SCHEME_RED,
                'bg-charcoal-50 text-charcoal-100': colorScheme === COLOR_SCHEME_GRAY,
                'bg-white-100 text-charcoal-100': colorScheme === COLOR_SCHEME_WHITE,
                'bg-charcoal-50 text-tempo-black-100': colorScheme === COLOR_SCHEME_TEMPO,
                'bg-cream-100 text-charcoal-100': colorScheme === COLOR_SCHEME_CREAM,
                'bg-tempo-navy-100 text-white-100': colorScheme == COLOR_SCHEME_TEMPO_BLUE,
              },
              'z-one'
            )}
          >
            <div className={cn('flex', 'items-center', titleStyle)}>
              {showIcon && <Icon className="iconLg mr-4" />}
              <span className={titleStyle ? titleStyle : 'h2'}>{title}</span>
              {Boolean(subtitle) && <span className={cn(subtitleStyle)}>{subtitle}</span>}
            </div>
            {dismissable && (
              <button onClick={onDismiss} className={xStyle}>
                <span className="sr">Close</span>
                <CloseX className="h-6 w-6" />
              </button>
            )}
          </div>
          <div
            className={cn(
              bodyPadding ? bodyPadding : cn('p-5', 'bp1:px-8', 'bp1:pt-6', 'bp1:pb-8'),
              bodyStyle
            )}
          >
            {children}
          </div>
        </div>
      </div>

      {dismissable ? (
        <button
          onClick={onDismiss}
          className="fixed left-0 top-0 z-modalBackground h-screen w-full cursor-default bg-blueberry-100 opacity-50"
        />
      ) : (
        <div className="fixed left-0 top-0 z-modalBackground h-screen w-full bg-blueberry-100 opacity-50" />
      )}
    </div>
  </FocusTrap>
);

Modal.propTypes = {
  bodyPadding: PropTypes.string,
  bodyStyle: PropTypes.string,
  colorScheme: PropTypes.oneOf([
    COLOR_SCHEME_GREEN,
    COLOR_SCHEME_GRAY,
    COLOR_SCHEME_RED,
    COLOR_SCHEME_WHITE,
    COLOR_SCHEME_TEMPO,
    COLOR_SCHEME_CREAM,
  ]),
  containerStyle: PropTypes.string,
  dismissable: PropTypes.bool,
  iconComponent: PropTypes.func,
  onDeactivate: PropTypes.func,
  onDismiss: PropTypes.func,
  showIcon: PropTypes.bool,
  title: PropTypes.string,
  topBarStyle: PropTypes.string,
  xStyle: PropTypes.string,
  titleStyle: PropTypes.string,
  subtitle: PropTypes.string,
  subtitleStyle: PropTypes.string,
};

export default Modal;
